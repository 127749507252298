<template>
    <div>
        <vs-table search stripe border :multiple="selection" v-model="selected" description :sst="true" :data="table.data"
            :max-items="table.length" :total="table.total" @search="handleSearch" @change-page="handleChangePage"
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <div
                    class="flex items-center justify-between p-2 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                    <span class="mr-2">Selection </span>
                    <vs-switch v-model="selection" @input="(val)=>{if (!val) this.selected=[]}" class="mr-2">
                        <span slot="on">On</span>
                        <span slot="off">Off</span>
                    </vs-switch>
                    <vs-button v-if="selected.length > 0 && this.$store.getters['user/hasPermissions']('edit')" size="small" class="mr-2" @click="handleApproveAll">Approve
                        All</vs-button>
                    <vs-button v-if="selected.length > 0 && this.$store.getters['user/hasPermissions']('edit')" size="small" class="mr-2" @click="handleRejectAll">Reject
                        All</vs-button>
                </div>
            </template>
            <template slot="thead">
                <vs-th>Action </vs-th>
                <vs-th sort-key="code">PO Data </vs-th>
                <vs-th sort-key="code">PO Type</vs-th>
                <vs-th sort-key="code">Warehouse</vs-th>
                <vs-th sort-key=""></vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                <vs-td></vs-td>
                <vs-th sort-key="">PO Value</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button v-if="$store.getters['user/hasPermissions']('view')" size="small" color="grey" icon-pack="feather" icon="icon-eye" title="Detail"
                                style="margin-right: 2px" @click="handleEdit(tr.id, 'detail')" />
                            <vs-button v-if="$store.getters['user/hasPermissions']('edit')" size="small" color="warning" icon-pack="feather" icon="icon-target" title="Inquiry"
                                style="margin-right: 2px" @click="inquiry=tr.id;inquiryPopUp=true" />  
                            <vs-popup class="inquiryPop"  title="Add Inquiry Note" :active.sync="inquiryPopUp">
                                <vs-textarea v-model="note" />
                                <vs-button size="small" title="Detail" style="margin-right: 2px" @click="inquiryPo()" >Submit</vs-button>
                            </vs-popup>   
                            <vs-button v-if="$store.getters['user/hasPermissions']('edit')" size="small" color="danger" icon-pack="feather" icon="icon-x-square" title="Reject"
                                style="margin-right: 2px" @click="handleReject(tr.id)" />
                            <vs-button v-if="$store.getters['user/hasPermissions']('edit')" size="small" color="success" icon-pack="feather" icon="icon-check" title="Approve"
                                @click="handleApprove(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.code }}</div>
                            <div v-if="tr.external_code">External Code : {{ tr.external_code }}</div>
                            <div>Date : {{ formatDate(tr.posting_date) }}</div>
                            <div>Term : {{ tr.payment_term_name }}</div>
                            <div>Eta : {{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.purchase_type.name }}</div>
                        <div v-if="tr.so_code">SO Code : {{ tr.so_code }}</div>
                    </vs-td>
                    <vs-td key="warehouse">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.warehouse.code }}</div>
                            <div>Name : {{ tr.warehouse.name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="supplier">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="poValue">
                        <div class="flex flex-col content-center">
                            <div>Currency : {{ tr.currency_code }}</div>
                            <div class="d-inline-flex-b"><span>Discount</span>{{ formatLocalNumber(tr.discount) }}</div>
                            <div class="d-inline-flex-b"><span>Charge</span>{{ formatLocalNumber(tr.total_charge) }}</div>
                            <div class="d-inline-flex-b"><span>Total Purchase</span>{{ formatLocalNumber(tr.sub_total) }}</div>
                            <div class="d-inline-flex-b"><span>Total Tax</span>{{ formatLocalNumber(tr.tax) }}</div>
                            <div class="d-inline-flex-b"><span>Grand Total</span>{{ formatLocalNumber(tr.total) }}</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        poTypeId: {
            type: String,
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Draft",
        },
    },
    data() {
        return {
            selection: true,
            deleteId: null,
            table: this.tableDefaultState(),
            selected: [],
            timeOuts: null,
            inquiry:0,
            note: "",
            inquiryPopUp:false,
        };
    },
    methods: {
        handleLog(v){console.log("logging :",v);},
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 500);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handleCreate() {
            this.customerId = null;
            this.detail = true;
        },
        handlePlan(id) {
            this.$router.push({
                name: "customer-visit-plan",
                params: { id: id },
            });
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.purchaseOrder.baseUrlPath}/approval/waiting`, {
                    params: {
                        limit: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_id: this.sourceWarehouseId,
                        supplier_id: this.supplierId,
                        po_type_ids: this.poTypeId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        this.table.data = resp.data.rows ? resp.data.rows : [];
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        inquiryPo() {
            this.$vs.loading();
            this.inquiryPopUp=false;
            this.$http.patch(`${this.$store.state.purchaseOrder.baseUrlPath}/approval/${this.inquiry}/inquiry`,{note:this.note})
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.dialog({
                            type: "alert",
                            color: "success",
                            title: `Success`,
                            text: resp.message,
                            accept: () => {
                                this.getData();
                            }
                        });
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "alert",
                            color: "danger",
                            title: `Unauthorized`,
                            text: resp.message,
                        });
                    }
                });
        },
        handleApprove(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Would you like to approve this request?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`${this.$store.state.purchaseOrder.baseUrlPath}/approval/${id}/approve`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "success",
                                    title: `Success`,
                                    text: resp.message,
                                    accept: () => {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "danger",
                                    title: `Unauthorized`,
                                    text: resp.message,
                                });
                            }
                        });
                },
            });
        },
        handleEdit(id, mode) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-edit",
                params: { id: id, mode: mode, isApproval: "Approval"},
            });
        },
        handleDetail(id, print) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-detail",
                params: { id: id },
                query: {print:print},
            });
        },
        handleReject(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to reject this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`${this.$store.state.purchaseOrder.baseUrlPath}/${id}/rejected`)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully rejected",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: error,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        });
                },
            });
        },
        handleApproveAll() {
            var ids = [];
            this.selected.forEach((item) => {
                ids.push(item.id);
            })

            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Would you like to approve all this request?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.put(`${this.$store.state.purchaseOrder.baseUrlPath}/approval/approves`, ids)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.getData();
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "danger",
                                    title: `Unauthorized`,
                                    text: resp.message,
                                });
                            }
                        });
                },
            });
        },
        handleRejectAll() {
            var ids = [];
            this.selected.forEach((item) => {
                ids.push(item.id);
            })

            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Would you like to reject all this request?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.put(`${this.$store.state.purchaseOrder.baseUrlPath}/approval/rejects`, ids)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.getData();
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "danger",
                                    title: `Unauthorized`,
                                    text: resp.message,
                                });
                            }
                        });
                },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() { 
        this.$store.dispatch("user/getPermissions", "purchasing-po-approval")
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
    },
};
</script>
  
<style>
.d-inline-flex-b{
    display: inline-flex; 
    justify-content: space-between;
}
</style>