<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action </vs-th>
                <vs-th sort-key="code">PO Data </vs-th>
                <vs-th sort-key="code">PO Type</vs-th>
                <vs-th sort-key="code">Warehouse</vs-th>
                <vs-th sort-key=""></vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                    <vs-td></vs-td>
                <vs-th sort-key="">PO Value</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit"
                                v-if="status === 'Draft' && $store.getters['user/hasPermissions']('edit')" title="Edit" style="margin-right: 5px"
                                @click="handleEdit(tr.id)" />
                            <vs-button size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                v-if="status === 'Draft' && $store.getters['user/hasPermissions']('edit')" title="Delete" style="margin-right: 5px"
                                @click="handleDelete(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                                v-if="status === 'Draft' && $store.getters['user/hasPermissions']('edit')" title="Request Approval" @click="handleApprovalRequest(tr.id)" />
                            <vs-button v-if="$store.getters['user/hasPermissions']('view')" size="small" color="grey" icon-pack="feather" icon="icon-eye"  title="Detail" style="margin-right: 5px"
                                @click="handleEdit(tr.id, 'detail')" />
                            <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x-square"
                                v-if="status === 'Waiting Approval' && $store.getters['user/hasPermissions']('cancel')" title="Cancel" @click="handleCancel(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.code }}</div>
                            <div v-if="tr.external_code">External Code : {{ tr.external_code }}</div>
                            <div>Date : {{ formatDate(tr.posting_date) }}</div>
                            <div>Term : {{ tr.payment_term_name }}</div>
                            <div>Eta : {{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.purchase_type.name }}</div>
                        <div v-if="tr.so_code">SO Code : {{ tr.so_code }}</div>
                    </vs-td>
                    <vs-td key="warehouse">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.warehouse.code }}</div>
                            <div>Name : {{ tr.warehouse.name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="supplier">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="poValue">
                        <div class="flex flex-col content-center">
                            <div>Currency : {{ tr.currency_code }}</div>
                            <div class="d-inline-flex-b"><span>Discount</span>{{ formatLocalNumber(tr.discount) }}</div>
                            <div class="d-inline-flex-b"><span>Charge</span>{{ formatLocalNumber(tr.total_charge) }}</div>
                            <div class="d-inline-flex-b"><span>Total Purchase</span>{{ formatLocalNumber(tr.sub_total) }}</div>
                            <div class="d-inline-flex-b"><span>Total Tax</span>{{ formatLocalNumber(tr.tax) }}</div>
                            <div class="d-inline-flex-b"><span>Grand Total</span>{{ formatLocalNumber(tr.total) }}</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        poTypeId: {
            type: String,
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Draft",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handleCreate() {
            this.customerId = null;
            this.detail = true;
        },
        handlePlan(id) {
            this.$router.push({
                name: "customer-visit-plan",
                params: { id: id },
            });
        },
        getData() {
            // console.log(this.table.length);
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.purchaseOrder.baseUrlPath}/approval`, {
                    params: {
                        limit: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_id: this.sourceWarehouseId,
                        supplier_id: this.supplierId,
                        po_type_ids: this.poTypeId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        this.table.data = resp.data.rows?resp.data.rows:[];
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleApprovalRequest(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to request approval this data",
                accept: this.actionApprovalRequest,
            });
        },
        actionApprovalRequest() {
            this.$vs.loading();
            this.$http
                .post(this.$store.state.outbound.simpleSto.baseUrlPath + "/change-stat/" + this.deleteId + "/" + this.status)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was success request to approve, check waiting approval tab.",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData();
                });
        },
        acceptApprovalRequest() {
            this.$vs.loading();
            this.$http
                .delete(this.$store.state.purchaseOrder.baseUrlPath + "/" + this.deleteId)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was success request to approve, check waiting approval tab.",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleEdit(id, mode) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-edit",
                params: { id: id, mode: mode, isApproval: "Approval"},
            });
        },
        handleDetail(id, print) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-detail",
                params: { id: id },
                query: {print:print},
            });
        },
        handleAssign(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to assign this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Assigned",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully assigned",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleAssignMultiple() {
            let routeAssignmentIDs = [];

            this.selectedRouteAssignmentToAssign.forEach((el) => {
                routeAssignmentIDs.push(el.id);
            });

            if (routeAssignmentIDs.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select data to assign",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to assign this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/status/multiple", {
                            status: "Assigned",
                            route_assignment_ids: routeAssignmentIDs,
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully assigned",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleDraft(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure change to draft this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Draft",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully change to draft",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleCancel(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post(this.$store.state.purchaseOrder.baseUrlPath + "/change-stat/" + this.deleteId + "/" + this.status)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully canceled",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
    },
    mounted() { 
        this.$store.dispatch("user/getPermissions", "purchasing-po-approval")
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
    },
};
</script>
  
<style>
.d-inline-flex-b{
    display: inline-flex; 
    justify-content: space-between;
}
</style>